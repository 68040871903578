/** @jsx jsx */
import { graphql } from "gatsby";
import React, { memo } from "react";
import { loadable } from "shared/helpers/loadableComponent";
import { jsx } from "theme-ui";

import WidgetWrapper from "../../WidgetWrapper";
import type { WidgetProps, WidgetUnion } from "../types";

const ContactForm = loadable(() => import("gatsby-theme-form"), {
  resolveComponent: (components) => components.ContactForm,
});

interface Props extends WidgetProps {
  widget: Extract<WidgetUnion, { __typename: "WidgetContactForm" }>;
}

const ContactFormWidget: React.FC<Props> = ({
  isFirst,
  isNested,
  isLast,
  widget,
  prevWidget,
  nextWidget,
  containerSize,
}) => {
  return (
    <WidgetWrapper
      isFirst={isFirst}
      isLast={isLast}
      isNested={isNested}
      containerSize={containerSize}
      widget={widget}
      prevWidget={prevWidget}
      nextWidget={nextWidget}
    >
      <ContactForm
        formId={widget.formId}
        formUrl={widget.contactFormShape?.endpoint}
        reCaptchaKey={widget.contactFormShape?.siteKey}
      />
    </WidgetWrapper>
  );
};

export const query = graphql`
  fragment WidgetContactForm on WidgetContactForm {
    id
    formId
    contactFormShape {
      endpoint
      siteKey
    }
    __typename
  }
`;

export default memo(ContactFormWidget);
